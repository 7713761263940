import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  InputBase,
  TablePagination,
} from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { getAllCampaigns } from '../../../api/campaigns';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useStyles } from './styles';
import UsersRow from '../../../components/UsersRow';
import { TablePaginationActions } from './TablePaginationActions';
import { EnhancedTableHead } from './EnhancedTableHead';

const descendingComparator = (a, b, orderBy) => {
  if (!orderBy) {
    return;
  }

  const getReferrerCode = (campaign) => campaign?.referrer?.code || 'z';

  const compareValues = (valueA, valueB) => {
    return typeof valueA === 'number' && typeof valueB === 'number'
      ? valueB - valueA
      : valueB.localeCompare(valueA);
  };

  const valueA = orderBy === 'referrer' ? getReferrerCode(a) : a[orderBy];
  const valueB = orderBy === 'referrer' ? getReferrerCode(b) : b[orderBy];

  return compareValues(valueA, valueB);
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export default function Users() {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [cachedCampaigns, setCachedCampaigns] = useState([]);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getCampaigns = () => {
    setLoading(true);
    getAllCampaigns({ search })
      .then((result) => {
        setCampaigns(result);
        setCachedCampaigns(result);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCampaigns();
  }, []);

  const keyPress = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (e.keyCode === 27) {
      setSearch('');
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(campaigns, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, campaigns]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchCampaigns = (searchValue) => {
    const normalizedSearchValue = searchValue.toLowerCase();
    return cachedCampaigns.filter((campaign) =>
      Object.values(campaign).some((key) => {
        if (
          Array.isArray(key) &&
          key.some((campaignsCampaign) =>
            campaignsCampaign.episode.title.toLowerCase().includes(normalizedSearchValue)
          )
        ) {
          return true;
        } else {
          return ('' + key).toLowerCase().includes(normalizedSearchValue);
        }
      })
    );
  };

  useEffect(() => {
    const newCampaigns = search ? searchCampaigns(search) : cachedCampaigns;
    setCampaigns(newCampaigns);
  }, [search, cachedCampaigns]);

  return (
    <>
      <Grid className={classes.wrapper} container item md={12} justifyContent="center">
        <Grid container item alignItems="center" justifyContent="space-between">
          <Grid className={classes.title} item>
            <Typography variant="h4">Campaigns</Typography>
          </Grid>
          <Grid item className={classes.searchInputWrapper}>
            <InputBase
              className={classes.input}
              placeholder="Search"
              value={search}
              disabled={loading}
              onKeyDown={keyPress}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ 'aria-label': 'search users' }}
            />
          </Grid>
        </Grid>
        {!loading ? (
          <TableContainer className={classes.tTableContainer} component={Paper}>
            <Table aria-label="simple table" className={classes.tTable}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={campaigns.length}
              />
              <TableBody>
                {visibleRows.map((campaign) => (
                  <UsersRow key={campaign._id} row={campaign} getCampaigns={getCampaigns} />
                ))}
                {!campaigns.length && (
                  <TableRow>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, { label: 'All', value: -1 }]}
              component="div"
              count={campaigns.length}
              rowsPerPage={rowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={classes.tTablePagination}
              ActionsComponent={TablePaginationActions}
            />
          </TableContainer>
        ) : (
          <Grid container item md={12} justifyContent="center">
            <FontAwesomeIcon icon={faSpinner} size="lg" spin />
          </Grid>
        )}
      </Grid>
    </>
  );
}
